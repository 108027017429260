import React, { useEffect, useState } from 'react';

type WindowWithTelegramAuth = Window & typeof globalThis & {
  onTelegramAuth: (user: TelegramUser) => void;
};

export function App() {
  const [user, setUser] = useState<TelegramUser | null>(null);

  const onAuth = (user: TelegramUser) => {
    setUser(user);
  };

  const onLogout = () => {
    setUser(null);
  };

  return (
    <>
      <h1>Hello world!</h1>
      <LoginWithTelegram size="large" onAuth={onAuth} />
      {user ? (
        <>
          <p>Logged in as @{user.username} ({user.id})</p>
          <button onClick={onLogout}>Logout</button>
        </>
      ) : null}
    </>
  );
}


interface TelegramUser {
  id: number;
  first_name: string;
  last_name: string;
  username: string;
}

interface LoginWithTelegramProps {
  size: 'large' | 'medium' | 'small';
  onAuth: (user: TelegramUser) => void;
}

function LoginWithTelegram({ size, onAuth }: LoginWithTelegramProps) {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://telegram.org/js/telegram-widget.js?22";
    script.async = true;
    script.setAttribute('data-telegram-login', 'zkmochi_bot');
    script.setAttribute('data-size', size);
    script.setAttribute('data-onauth', 'onTelegramAuth(user)');
    script.setAttribute('data-request-access', 'write');
    document.body.appendChild(script);

    (window as WindowWithTelegramAuth).onTelegramAuth = function(user) {
      onAuth(user);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null;
}
